import { mapGetters, mapMutations, mapActions} from 'vuex';

const _ = require('lodash');

export default {
    props: ['popupType', 'selectedItem','newProduct'],
    data() {
        return {
            toggleInputPrice: false,
            toggleInputCount: false,
            blockAddProduct: false,
            dirtyUpSell: false,
            has_upsell: false,
            form: {
                department_id: 0,
                customer_name: '',
                email: '',
                address: '',
                country: '',
                phone: 0,
                site: '',
                ip: '',
                comment: '',
                cancel_reason: '0',
                shipment_method_id: 0,
                order_status_id: 1,
                products:[], 
                has_upsell: 0,
            },
            deliveryType:[],
            deliveryChoice: 0,
            ttn:'',
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'isbn',
                    label:'Штрих-код',
                },
                {
                    key:'product',
                    label:'Товар',
                },
                {
                    key:'price',
                    label:'Ціна',
                },
                {
                    key:'price_air',
                    label:'Повітря',
                },
                {
                    key:'count',
                    label:'Кількість',
                },
                {
                    key:'result',
                    label:'Всього',
                },
                {
                    key:'orderProductRemove',
                    label:'',
                },
            ],
            tableItems:[],
            tableUpItems:[],
            cancelReasonList:[
                {
                    id:'0',
                    title:'-Нет значения-'
                },
                {
                    id:'1',
                    title:'Дублирующая заявка'
                },
                {
                    id:'2',
                    title:'Клиент отказался или передумал'
                },
                {
                    id:'3',
                    title:'Клиент не оставлял заявку'
                },
                {
                    id:'4',
                    title:'Некорректный номер телефона'
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            order:'orders/order',
            departments:'catalog/departments',
            shipmentMethods:'orders/shipmentMethods',
            orderStatusList:'orders/orderStatusList',
            paymentMethods:'orders/paymentMethods',
            usersGroup: 'contacts/usersGroup',
            users:'contacts/users',
            profile:'profile/profile',
        }),
    },
    watch:{
        newProduct(e){
            if(e){ 
                if(!e.is_upsell && this.form.products.find(item => item.product_id === e.product_id && this.tableItems.find(item => item.product_id === e.product_id))){
                    this.tableItems.map(item => {if(+item.product_id === +e.product_id && item.upSell === e.is_upsell){ item.count =  +item.count + +e.quantity}})
                    this.form.products.map(item => {if(+item.product_id === +e.product_id && item.is_upsell === e.is_upsell){ item.quantity =  +item.quantity + +e.quantity}})
                }
                else if(e.is_upsell && this.form.products.find(item => item.product_id === e.product_id && this.tableUpItems.find(item => item.product_id === e.product_id))){
                    this.tableUpItems.map(item => {if(+item.product_id === +e.product_id && item.upSell === e.is_upsell){ item.count =  +item.count + +e.quantity}})
                    this.form.products.map(item => {if(+item.product_id === +e.product_id && item.is_upsell === e.is_upsell){ item.quantity =  +item.quantity + +e.quantity}})
                }else{
                    this.pushTableItem(e)
                    this.form.products.push(e);
                }
            }
        },
        order(e){
            if(e){
                this.form = _.clone(e) 
                this.has_upsell = Boolean(e.has_upsell);
                this.tableItems = [];
                this.tableUpItems = [];
                e.products.map(item=>{
                    this.pushTableItem(item)
                })
            }
        },
        has_upsell(){
            this.dirtyUpSell = true;
        },
        form(){
            this.changedOrderStatus();
        }
    },
    created() {
        this.getUsers();
        this.getUsersGroup();
        this.shipmentMethods.data.map(item=>{
            this.deliveryType.push(item);
        })
    },
    methods: {
        changedOrderStatus(){
            if(this.form.order_status_id === 15 || this.form.order_status_id === 22 || this.form.order_status_id === 23){
                this.blockAddProduct = true;
            }else{
                this.blockAddProduct = false;
            }
        },
        removeProduct(productItem){
            if(productItem.upSell === 1){
                this.tableUpItems = this.tableUpItems.filter((item => item.id !== productItem.id));
            }else if(productItem.upSell === 0){
                this.tableItems = this.tableItems.filter((item => item.id !== productItem.id));
            } 
            console.log('productItem - ', productItem);
            this.form.products =  this.form.products.filter((item => item.id !== productItem.id));
            this.removeProductInOrder(productItem.id).then(res=>{
                if(res.status){
                    this.$toasted.success('Товар успешно удален', {
                        duration: 3000
                    });
                }else{
                    this.$toasted.error('Произошла ошибка при удалении', {
                        duration: 3000
                    });
                }
            })
            console.log('this.form.products - ', this.form.products);
        },
        findPhoneOrders(){
            this.$emit('openPhoneOrders')

            const phone = this.form.phone.replace(/\D+/g, "");
            this.getOrdersForPhone({phone: phone})
        },
        pushTableItem(item){ 
            if(item.is_upsell === 0){ 
                this.tableItems.push({
                    id: item.id ? item.id : item.product_id,
                    isbn: item.product.isbn,
                    product: item.product.title,
                    price: Number(item.price).toFixed(2),
                    price_air: item.price_air ? Number(item.price_air).toFixed(2) : 0,
                    count: Number(item.quantity),
                    result: Number((+item.price + +item.price_air)  * item.quantity).toFixed(2),
                    orderProductRemove: '1',
                    product_id: item.product_id,
                    upSell: 0
                })
            }else if(item.is_upsell === 1){
                this.tableUpItems.push({
                    id: item.id ? item.id : item.product_id,
                    isbn: item.product.isbn,
                    product: item.product.title,
                    price: Number(item.price).toFixed(2),
                    price_air: item.price_air ? Number(item.price_air).toFixed(2) : 0,
                    count: Number(item.quantity),
                    result: (+item.price + +item.price_air) * item.quantity,
                    orderProductRemove: '1',
                    product_id: item.product_id,
                    upSell: 1
                }); 
            }
        },
        updatePrice(value, index, itemProduct){
            this.updateTableItemAllPrice(itemProduct);
            this.form.products.map(item => {
                if(item.id === itemProduct.id && item.is_upsell === itemProduct.upSell){
                    item.price = value
                }
            })
        },
        updatePriceAir(value, index, itemProduct){
            this.updateTableItemAllPrice(itemProduct); 
            this.form.products.map(item => {
                if(item.id === itemProduct.id && item.is_upsell === itemProduct.upSell){
                    item.price_air = value
                }
            })
        },
        updateCount(value, itemProduct){
            this.updateTableItemAllPrice(itemProduct);
            this.form.products.map(item => {
                if(item.id === itemProduct.id && item.is_upsell === itemProduct.upSell){
                    item.quantity = value
                }
            })
        },
        updateTableItemAllPrice(itemProduct){
            this.tableUpItems.map(item => {if(+item.product_id === +itemProduct.product_id && item.upSell === itemProduct.upSell){item.result = ((+item.price + +item.price_air) * item.count).toFixed(2)}})
            this.tableItems.map(item => {if(+item.product_id === +itemProduct.product_id && item.upSell === itemProduct.upSell){ item.result = ((+item.price + +item.price_air) * item.count).toFixed(2)}})
        },
        closePopup() {
            this.$emit('closePopup')
        },
        openOrderChangesList(){
            this.$emit('openOrderChangesList', {form: this.form})
        },
        openRememberPopup(){
          this.$emit('togglePopupRemember', {status:true, form: this.form})
        },
        openPopupSearchProduct(){
            this.changeIsUpsell(0);
            this.toggleShowAddProductPopUp(true);
        },
        openPopupUpSellProduct(){
            this.changeIsUpsell(1);
            this.toggleShowAddProductPopUp(true);
        },
        openNpTtn(){
          this.sendChanges(false);
          this.$emit('openNpTtn', {status:true, form: this.form})
        },
        openInfoTtn(){
          this.$emit('openInfoTtn');
        },
        sendChanges(closeAfter) {
            const phone = this.form.phone.replace(/\D+/g, "");
            const reg = '^\\+?3?8?(0(67|68|96|97|98|66|50|99|95|63|73|93|91)\\d{7})$';
            this.form.has_upsell = Number(this.has_upsell);
            if(phone.toString().match(reg)){
                if(!this.form.employee_id){
                    this.form.employee_id = this.profile.id;
                }
                if(this.order){
                    const payload = {
                        id: this.order.id,
                        data:this.form
                    }
                    this.$emit('changeOrder', {payload: payload, closeAfter: closeAfter})
                }else{
                    this.$emit('sendOrder', {form: this.form, closeAfter: closeAfter})
                }
            }else{
                this.$toasted.error('Не правильный номер оператора', {
                    duration: 3000
                });
            }
        },
        allPrice(){
            let allPrice = 0;
            if(this.tableItems){
                this.tableItems.map(item=>{
                    allPrice += Number(item.count) * (Number(item.price) + Number(item.price_air));
                })
                return allPrice.toFixed(2);
            }else{
                return 0;
            }
        },
        allCount(){
            let allCount = 0;
            if(this.tableItems){
                this.tableItems.map(item=>{
                    allCount +=  Number(item.count);
                })
                return allCount.toFixed(2);
            }else{
                return 0;
            }
        },
        allUpPrice(){
            let allPrice = 0;
            if(this.tableUpItems.length){
                this.tableUpItems.map(item=>{
                    allPrice += (Number(item.price) + Number(item.price_air)) * Number(item.count)
                })
            }
            return allPrice;
        },
        allUpCount(){
            let allCount = 0;
            this.tableUpItems.map(item=>{
                allCount +=  Number(item.count);
            })
            return allCount;
        },
        showProduct(item){
            this.$emit('showProduct', item.product_id);
        },
        showMassagePopup(){
            this.$emit('showMassage', {status:true, form: this.form});
        },
        showCallHistory(){
            this.$emit('showCallHistory', {status:true});
        },
        statusEdit(id){
            if(id !== 15){
                return true
            }else{
                if(this.profile.user_group_id === 26){
                    return true
                }else{
                    return false
                }
            }
        },
        ...mapActions({
            getUsers:'contacts/getUsers',
            showOrder:'orders/showOrder',
            getUsersGroup:'contacts/getUsersGroup',
            getOrdersForPhone:'orders/getOrdersForPhone',
            removeProductInOrder:'orders/removeProductInOrder'
        }),
        ...mapMutations({
            toggleShowAddProductPopUp: 'config/toggleShowAddProductPopUp',
            changeIsUpsell: 'orders/changeIsUpsell',
            changeOrder: 'orders/changeOrder',
        }),
    },
    beforeDestroy() {
        if(this.order && this.order.status_open){
            this.showOrder(this.order.id)
        }
        this.changeOrder(null);
    }
}
